var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-avatar",
    {
      staticClass: "white--text text-caption",
      class: _vm.complete ? "complete" : "incomplete",
      attrs: { height: "28", "min-width": "28", width: "28" }
    },
    [_vm._v(" " + _vm._s(_vm.language.code) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }