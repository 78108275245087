var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("page-header-bar", [
        !_vm.dataLoading
          ? _c("div", { staticClass: "d-flex p-relative white--text" }, [
              _c("div", { staticClass: "flex-grow-1 d-flex flex-column" }, [
                _c(
                  "div",
                  { staticClass: "fab-container p-absolute" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "floating-button",
                        attrs: {
                          color: "grey lighten-2 black--text",
                          dark: "",
                          small: "",
                          fab: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.closeSurveyTranslator()
                          }
                        }
                      },
                      [
                        _c("v-icon", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.translation ? "close" : "arrow_back") +
                              " "
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("h5", { staticClass: "text-h5 mb-2" }, [
                  _vm._v(" " + _vm._s(_vm.surveyTitle) + " ")
                ]),
                _c(
                  "div",
                  { staticClass: "d-flex align-center mb-1" },
                  [
                    _c("v-icon", { staticClass: "white--text mr-2" }, [
                      _vm._v(" format_list_numbered ")
                    ]),
                    _c(
                      "div",
                      { staticClass: "mr-2 font-weight-regular text-h6" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.survey.userQuestionCount) +
                            " " +
                            _vm._s(
                              _vm.survey.userQuestionCount === 1
                                ? "Question"
                                : "Questions"
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c("survey-language-status", {
                      attrs: {
                        highlight: _vm.selectedLanguage,
                        "language-status": _vm.languageTranslationStatus
                      },
                      on: { "language-clicked": _vm.setDisplayLanguage }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c("v-icon", { staticClass: "white--text mr-2" }, [
                      _vm._v(" record_voice_over ")
                    ]),
                    _c("h6", { staticClass: "text-h6" }, [
                      _vm._v(" " + _vm._s(_vm.responseCount) + " ")
                    ])
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "d-flex flex-column justify-center" },
                [
                  _c("survey-page-header-buttons", {
                    staticClass: "align-center",
                    attrs: {
                      "survey-status": _vm.survey.status,
                      "survey-has-questions": _vm.survey.questions.length > 1,
                      "survey-has-responses": _vm.survey.responseCount > 0
                    },
                    on: {
                      "show-survey-preview": function($event) {
                        _vm.showPreviewDialog = true
                      },
                      "show-survey-publish": _vm.showSurveyPublishDialog,
                      "edit-survey": function($event) {
                        _vm.editSurvey = true
                      },
                      "edit-survey-disclaimer": function($event) {
                        _vm.showDisclaimerDialog = true
                      },
                      "clear-survey-responses": function($event) {
                        _vm.showClearResponsesDialog = true
                      }
                    }
                  }),
                  _c("v-switch", {
                    attrs: {
                      "v-model": _vm.survey.showDisclaimer,
                      "hide-details": "true",
                      color: "white",
                      "input-value": _vm.survey.showDisclaimer
                    },
                    on: {
                      change: function($event) {
                        return _vm.toggleShowDisclaimer($event)
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function() {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "white--text font-weight-regular"
                                },
                                [_vm._v(" Show Survey Disclaimer ")]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2406606667
                    )
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ]),
      _vm.dataLoading
        ? _c(
            "div",
            [
              _c(
                "v-container",
                { staticClass: "pa-2" },
                [_c("question-list-loader")],
                1
              )
            ],
            1
          )
        : _c(
            "v-container",
            { staticClass: "pa-2" },
            [
              !_vm.translation
                ? _c(
                    "v-tabs",
                    {
                      staticClass: "pa-8",
                      attrs: { grow: "" },
                      model: {
                        value: _vm.activeTab,
                        callback: function($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab"
                      }
                    },
                    [
                      _vm._l(_vm.tabs, function(tab, i) {
                        return _c(
                          "div",
                          { key: tab.href, staticClass: "d-flex flex-grow-1" },
                          [
                            !tab.isDisabled
                              ? _c(
                                  "v-tab",
                                  { attrs: { href: tab.href } },
                                  [
                                    _c("v-icon", { staticClass: "mr-2" }, [
                                      _vm._v(_vm._s(tab.icon))
                                    ]),
                                    _vm._v(_vm._s(tab.title) + " ")
                                  ],
                                  1
                                )
                              : _c(
                                  "v-tooltip",
                                  {
                                    key: i,
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "div",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "d-flex flex-grow-1"
                                                  },
                                                  _vm.hasResponses ? {} : on
                                                ),
                                                [
                                                  _c(
                                                    "v-tab",
                                                    {
                                                      attrs: {
                                                        href: tab.href,
                                                        disabled: tab.isDisabled
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(tab.icon)
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(tab.title) +
                                                          " "
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " " + _vm._s(tab.tabToolTipText) + " "
                                      )
                                    ])
                                  ]
                                )
                          ],
                          1
                        )
                      }),
                      _c(
                        "v-tab-item",
                        { attrs: { value: "questions" } },
                        [
                          _c("questions-view", {
                            attrs: {
                              survey: _vm.survey,
                              questions: _vm.questions,
                              "question-table-key": _vm.questionTableKey,
                              "data-loading": _vm.dataLoading
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        { attrs: { value: "dashboard" } },
                        [
                          _c("dashboard-view", {
                            attrs: {
                              survey: _vm.survey,
                              stats: _vm.questionStats,
                              "display-language": _vm.displayLanguage
                            },
                            on: { "fetch-stats-with-date": _vm.fetchStats }
                          })
                        ],
                        1
                      ),
                      _vm.survey.publicDisplayFeatureEnabled
                        ? _c(
                            "v-tab-item",
                            { attrs: { value: "public-display" } },
                            [
                              _c("public-display-config-view", {
                                staticClass: "mt-4",
                                attrs: { survey: _vm.survey }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-tab-item",
                        { attrs: { value: "export" } },
                        [
                          _c("survey-data-export-card", {
                            staticClass: "mt-4",
                            attrs: {
                              "start-date": _vm.survey.surveyStart,
                              "end-date": _vm.survey.surveyEnd,
                              survey: _vm.survey
                            }
                          })
                        ],
                        1
                      )
                    ],
                    2
                  )
                : _c("survey-translator", {
                    attrs: {
                      survey: _vm.survey,
                      "translation-language": _vm.translation
                    },
                    on: {
                      "update-translations": _vm.updateTranslations,
                      close: _vm.closeTranslate
                    }
                  }),
              _c("survey-preview-dialog", {
                attrs: {
                  show: _vm.showPreviewDialog,
                  "selected-survey": _vm.survey,
                  "questions-list": _vm.previewQuestions
                },
                on: {
                  "update:show": function($event) {
                    _vm.showPreviewDialog = $event
                  }
                }
              }),
              _c("survey-dialog", {
                attrs: {
                  show: _vm.editSurvey,
                  survey: _vm.survey,
                  organisations: _vm.organisations
                },
                on: {
                  "update:show": function($event) {
                    _vm.editSurvey = $event
                  },
                  save: _vm.handleSurveySaved
                }
              }),
              _c("survey-disclaimer-dialog", {
                attrs: { survey: _vm.survey },
                model: {
                  value: _vm.showDisclaimerDialog,
                  callback: function($$v) {
                    _vm.showDisclaimerDialog = $$v
                  },
                  expression: "showDisclaimerDialog"
                }
              }),
              _c("survey-action-dialog", {
                attrs: {
                  show: _vm.showActionDialog,
                  survey: _vm.survey,
                  action: _vm.surveyAction
                },
                on: {
                  "update:show": function($event) {
                    _vm.showActionDialog = $event
                  },
                  "dialog-action-confirmed": _vm.handleDialogActionConfirmed
                }
              }),
              _c("clear-survey-responses-confirmation-dialog", {
                attrs: {
                  show: _vm.showClearResponsesDialog,
                  survey: _vm.survey
                },
                on: {
                  "update:show": function($event) {
                    _vm.showClearResponsesDialog = $event
                  },
                  "clear-responses": function($event) {
                    return _vm.clearSurveyResponses()
                  },
                  cancel: function($event) {
                    _vm.showClearResponsesDialog = false
                  }
                }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }