<template>
  <v-card
    class="survey-card d-flex flex-column"
    hover
    @click="routeToSurveyPage"
  >
    <survey-card-header-container
      :image-id="survey.introBackgroundImageId"
      class="p-relative"
    >
      <v-card-title class="pa-0 h-100 d-flex flex-nowrap">
        <div class="align-self-end flex-grow-1 white--text">
          <transition name="survey-field" mode="out-in">
            <div
              :key="survey.title"
              class="text-h5 survey-title font-weight-bold clickable"
            >
              {{ survey.title }}
            </div>
          </transition>
          <div v-if="showOrganisation" class="text-subtitle-1">
            {{ organisationName }}
          </div>
        </div>
      </v-card-title>
      <survey-actions-menu
        class="survey-actions-menu"
        :survey-status="survey.status"
        @action-click="handleSurveyAction"
      />
    </survey-card-header-container>
    <v-container class="my-0 pa-4 d-flex flex-column flex-grow-1">
      <v-row>
        <v-col
          cols="6"
          class="question-count text-h6 font-weight-medium grey--text text--darken-2 pt-0"
        >
          <transition name="survey-field" mode="out-in">
            <span :key="survey.userQuestionCount">
              {{ survey.userQuestionCount }}
              {{
                survey.userQuestionCount === 1
                  ? 'question'
                  : 'questions'
              }}
            </span>
          </transition>
        </v-col>
        <v-col cols="6" class="pt-0 h-100">
          <survey-language-status
            :language-status="languageTranslationStatus"
            class="language-chips"
          />
        </v-col>
      </v-row>
      <survey-issues
        v-if="!isPublished"
        :survey="survey"
        :survey-issues="issues"
      />
      <transition name="survey-field" mode="out-in">
        <span :key="isPublished">
          <v-row v-if="isPublished">
            <v-col cols="6" class="pt-3 pb-0">
              <transition
                :key="survey.responseCount"
                name="survey-field"
                mode="out-in"
              >
                <span :key="survey.responseCount">
                  <p
                    class="count grey--text text--darken-4 text-h4 mb-0 font-weight-medium"
                  >
                    {{ survey.responseCount }}
                  </p>
                  <p class="grey--text text-subtitle-1 mb-0">
                    {{
                      survey.responseCount === 1
                        ? 'voice heard'
                        : 'voices heard'
                    }}
                  </p>
                </span>
              </transition>
            </v-col>
            <v-col cols="6" class="d-flex pb-0 pr-4 justify-end">
              <status-chip
                :status="survey.status"
                class="align-self-end"
              />
            </v-col>
          </v-row>
          <v-row v-else>
            <v-spacer />
            <v-col cols="6" class="pt-3 pb-0 d-flex justify-end">
              <status-chip :status="survey.status" />
            </v-col>
          </v-row>
        </span>
      </transition>
    </v-container>
  </v-card>
</template>

<script>
import { surveyHelper } from '@/mixins/SuperUtils';
import SurveyIssues from './SurveyIssues';
import SurveyLanguageStatus from '@/components/Common/SurveyLanguageStatus';
import SurveyActionsMenu from './SurveyActionsMenu';
import SurveyCardHeaderContainer from './SurveyCardHeaderContainer';
import StatusChip from '@/components/Common/StatusChip';
import { SurveyActions } from '@/enums';
export default {
  name: 'SurveyCard',
  components: {
    SurveyIssues,
    SurveyLanguageStatus,
    SurveyActionsMenu,
    SurveyCardHeaderContainer,
    StatusChip
  },
  mixins: [surveyHelper],
  props: {
    survey: {
      type: Object,
      required: true
    },
    organisationName: {
      type: String,
      required: false,
      default: undefined
    },
    showOrganisation: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    handleSurveyAction(action) {
      if (action == SurveyActions.RESPONSES) {
        this.$router.push({
          path: `/surveys/${this.survey.id}`,
          query: { tab: 'responseStats' }
        });
      } else {
        this.$emit('survey-action-request', action);
      }
    },
    routeToSurveyPage() {
      this.$router.push({
        path: `/surveys/${this.survey.id}`
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.survey-card {
  width: 100%;
}
.language-chips {
  display: flex;
  justify-content: flex-end;
}
.survey-title {
  display: inline-block;
  flex-grow: 1;
  word-break: normal;
  align-self: flex-end;
  // TODO: Remove hack to fix tricky CSS
  // This explicit width is in here because of relative positioning
  // of the menu button (i.e. to stop the title flowing into the menu button)
  width: 280px;
}

// TODO: Remove hack to fix tricky CSS on the header where
// adding a VImage results in the flex layout breaking,
// causing survey-title to be centered.
::v-deep .v-responsive__content {
  height: 100%;
}

.survey-actions-menu {
  display: inline-block;
  flex-grow: 0;
  align-self: flex-start;
  .survey-actions {
    width: 32px;
    height: 32px;
    border-radius: 18px;
    background-color: transparent;
  }
  .survey-actions i {
    width: 32px;
  }
  .survey-actions:hover {
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
  }
}

.survey-field-enter-active,
.survey-field-leave-active {
  transition: opacity 0.25s;
}
.survey-field-enter,
.survey-field-leave-to {
  opacity: 0;
}
</style>
