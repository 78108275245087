<template>
  <v-form ref="form" class="p-relative">
    <v-card-text>
      <v-text-field
        v-model="model.name"
        prepend-icon="insert_comment"
        label="Name"
        :rules="validationRules.name"
        autofocus
        :hint="isRequired"
      ></v-text-field>
      <v-text-field
        v-model="model.address"
        prepend-icon="domain"
        label="Address"
      ></v-text-field>
      <v-text-field
        v-model="model.ipadUsername"
        prepend-icon="alternate_email"
        label="iPad Username"
        :rules="validationRules.ipadUsername"
        persistent-hint
        :hint="isRequired"
      ></v-text-field>
      <div style="max-width: 50%;">
        <v-text-field
          v-model="model.ipadPassword"
          class="password"
          prepend-icon="lock"
          label="iPad Password"
          :append-icon="passwordVisibilityIcon"
          :type="passwordFieldType"
          :rules="validatePassword"
          :placeholder="passwordPlaceholder"
          persistent-hint
          :hint="isRequired"
          @click:append="showPassword = !showPassword"
          @focus="passwordFocus = true"
          @blur="passwordFocus = false"
        ></v-text-field>
      </div>
      <language-selector
        :primary-language.sync="model.primaryLanguage"
        :secondary-languages.sync="model.secondaryLanguages"
      />
    </v-card-text>
    <v-card-actions class="pa-6">
      <form-summary-message
        v-if="showDisclaimerUpdatePrompt"
        icon="warning"
        :message="disclaimerUpdatePrompt"
      />
      <v-spacer></v-spacer>
      <v-btn class="mr-0" @click="closeDialog">
        Cancel
      </v-btn>
      <v-btn color="success" class="ml-4" @click="submit">
        {{ submitButtonText }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import FormSummaryMessage from '@/components/Common/FormSummaryMessage';
import LanguageSelector from '@/components/Common/LanguageSelector';
import { mapActions } from 'vuex';
import { naiveClone } from '@/utils';

export default {
  name: 'OrganisationForm',
  components: {
    FormSummaryMessage,
    LanguageSelector
  },
  props: {
    organisation: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    model: undefined,
    showPassword: false,
    passwordFocus: false
  }),
  computed: {
    defaultOrganisation() {
      return {
        name: '',
        ipadUsername: '',
        ipadPassword: '',
        primaryLanguage: '',
        secondaryLanguages: [],
        address: ''
      };
    },
    isEditing() {
      return !!this.organisation.id;
    },
    validationRules() {
      return {
        name: [v => !!v || 'Name is required'],
        ipadUsername: [v => !!v || 'iPad username is required'],
        ipadPassword: [v => !!v || 'iPad password is required']
      };
    },
    submitButtonText() {
      return this.isEditing ? 'Save' : 'Create';
    },
    passwordVisibilityIcon() {
      return this.model.ipadPassword
        ? this.showPassword
          ? 'visibility_off'
          : 'visibility'
        : '';
    },
    passwordFieldType() {
      return this.showPassword ? 'text' : 'password';
    },
    isRequired() {
      return !this.isEditing ? 'Required *' : '';
    },
    validatePassword() {
      return !this.isEditing ? this.validationRules.ipadPassword : [];
    },
    passwordPlaceholder() {
      return this.isEditing && !this.passwordFocus ? '●●●●●●●●' : '';
    },
    nameChanged() {
      return this.organisation.name !== this.model.name;
    },
    addressChanged() {
      return this.organisation.address !== this.model.address;
    },
    nameOrAddressChanged() {
      return this.nameChanged || this.addressChanged;
    },
    showDisclaimerUpdatePrompt() {
      return this.isEditing && this.nameOrAddressChanged;
    },
    disclaimerUpdatePrompt() {
      const changes = [];
      if (this.nameChanged) changes.push('Name');
      if (this.addressChanged) changes.push('Address');
      const changedFields = changes.join(' and ');

      return `Disclaimers will needed to be updated manually to reflect changes to ${changedFields}.`;
    }
  },
  watch: {
    organisation() {
      this.isEditing
        ? (this.model = { ...this.model, ...this.organisation })
        : (this.model = this.defaultOrganisation);
    }
  },
  created() {
    this.model = this.defaultOrganisation;
    if (this.organisation) {
      Object.assign(this.model, this.organisation);
    }
  },
  methods: {
    ...mapActions('organisation', [
      'addOrganisation',
      'editOrganisation'
    ]),
    closeDialog() {
      this.$refs.form.resetValidation();
      this.model = naiveClone(this.defaultOrganisation);
      this.$emit('close');
    },
    submit() {
      if (!this.$refs.form.validate()) return;

      const save = this.isEditing
        ? this.editOrganisation
        : this.addOrganisation;

      save(this.model).then(() => {
        this.closeDialog();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-input.password input::placeholder {
  color: map-get($grey, 'darken-1');
}
</style>
