<template>
  <div class="d-flex">
    <v-tooltip v-for="(btn, i) in buttons" :key="i" top>
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <v-btn
            :color="btn.colors"
            class="mr-4"
            width="36px"
            height="36px"
            :disabled="btn.disabled"
            x-small
            @click="handleClick(btn.event)"
          >
            <v-icon :class="btn.iconClasses">{{ btn.icon }}</v-icon>
          </v-btn>
        </div>
      </template>
      <span>{{ btn.toolTipText }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { SurveyStatus, SurveyActions } from '@/enums';
import { mapState } from 'vuex';

export default {
  name: 'SurveyPageHeaderButtons',
  props: {
    surveyStatus: {
      type: String,
      required: true
    },
    surveyHasQuestions: {
      type: Boolean,
      required: true
    },
    surveyHasResponses: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState('survey', ['survey']),
    surveyPublished() {
      return this.surveyStatus == SurveyStatus.PUBLISHED;
    },
    buttons() {
      const colors = 'grey lighten-5 grey--text text--darken-1';

      return [
        {
          icon: 'create',
          toolTipText: 'Edit Survey',
          colors,
          event: 'edit-survey'
        },
        {
          icon: 'info',
          toolTipText: 'Survey Disclaimer',
          disabled: !this.survey.showDisclaimer,
          colors,
          event: 'edit-survey-disclaimer'
        },
        {
          icon: 'play_circle_outline',
          toolTipText: this.surveyHasQuestions
            ? 'Survey Preview'
            : 'Add a question to preview the survey',
          disabled: !this.surveyHasQuestions,
          colors,
          event: 'show-survey-preview'
        },
        {
          icon: 'speaker_notes_off',
          toolTipText: 'Clear Survey Responses',
          colors,
          disabled: !this.surveyHasResponses,
          event: 'clear-survey-responses'
        },
        {
          icon: 'publish',
          iconClasses: `material-icons ${
            this.surveyPublished ? 't-flipped-y' : ''
          }`,
          colors: this.surveyPublished
            ? 'red white--text'
            : 'teal white--text',
          toolTipText: `${
            this.surveyPublished ? 'Unpublish' : 'Publish'
          } survey`,
          event: this.surveyPublished
            ? SurveyActions.UNPUBLISH
            : SurveyActions.PUBLISH
        }
      ];
    }
  },
  methods: {
    handleClick(event) {
      if (
        event === SurveyActions.PUBLISH ||
        event === SurveyActions.UNPUBLISH
      ) {
        this.$emit('show-survey-publish', event);
      } else {
        this.$emit(event);
      }
    }
  }
};
</script>
