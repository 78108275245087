<template>
  <v-container
    id="public-display-access-code-content"
    fill-height
    fluid
  >
    <!-- Muse logo -->
    <v-row align="center" justify="center">
      <v-col align="center">
        <muse-logo
          class="mb-2"
          alt="muse - logo"
          src="../assets/svgs/MuseLogo.svg"
        />
        <h1>Public Display</h1>
      </v-col>
    </v-row>

    <!-- Survey name -->
    <v-row align="center" justify="center">
      <v-col align="center">
        <div v-if="dataLoading">
          <v-progress-circular indeterminate></v-progress-circular>
        </div>
        <div v-else class="text-h1 6rem font-weight-bold">
          {{ organisationName }}<br />
          {{ surveyTitle }}
        </div>
      </v-col>
    </v-row>

    <!-- Code entry -->
    <v-row align="center" justify="center">
      <v-col v-if="!dataLoading" align="center">
        <div class="text-h4">
          Enter your {{ accessCodeLength }}-digit access code:
        </div>
        <div class="input-wrapper">
          <AccessCodeInput
            placeholder=""
            :survey-id="surveyId"
            :access-code-length="accessCodeLength"
            @access-granted="onAccessGranted"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import AccessCodeInput from '../components/Common/AccessCodeInput.vue';
import MuseLogo from '!!vue-svg-loader!../assets/svgs/MuseLogo.svg';

export default {
  components: { MuseLogo, AccessCodeInput },
  mixins: [],
  props: {},
  data: () => {
    return {
      dataLoading: true,
      surveyTitle: undefined,
      organisationName: undefined
    };
  },
  computed: {
    surveyId() {
      return this.$route.params.id;
    },
    accessCodeLength() {
      return 4;
    }
  },
  mounted() {
    this.fetchPublicSurveyInfo({ surveyId: this.surveyId }).then(
      meta => {
        this.surveyTitle = meta.title;
        this.organisationName = meta.organisationName;
        this.dataLoading = false;
      }
    );
  },
  methods: {
    ...mapActions({
      fetchPublicSurveyInfo: 'publicDisplay/fetchPublicSurveyInfo',
      fetchOrganisation: 'organisation/fetchOrganisation',
      grantAccess: 'publicDisplay/grantAccess',
      denyAccess: 'publicDisplay/denyAccess'
    }),
    onAccessGranted(code) {
      this.$router.push(
        `/surveys/${this.surveyId}/tv?accessCode=${code}`
      );
    },
    onAccessDenied() {
      this.denyAccess();
    }
  }
};
</script>

<style lang="scss">
#public-display-access-code-content {
  background: linear-gradient(270deg, #ff7079 35.42%, #b34c79 100%);
  color: white;
}
</style>
