var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      attrs: {
        id: "public-display-access-code-content",
        "fill-height": "",
        fluid: ""
      }
    },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { align: "center" } },
            [
              _c("muse-logo", {
                staticClass: "mb-2",
                attrs: {
                  alt: "muse - logo",
                  src: "../assets/svgs/MuseLogo.svg"
                }
              }),
              _c("h1", [_vm._v("Public Display")])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c("v-col", { attrs: { align: "center" } }, [
            _vm.dataLoading
              ? _c(
                  "div",
                  [_c("v-progress-circular", { attrs: { indeterminate: "" } })],
                  1
                )
              : _c("div", { staticClass: "text-h1 6rem font-weight-bold" }, [
                  _vm._v(" " + _vm._s(_vm.organisationName)),
                  _c("br"),
                  _vm._v(" " + _vm._s(_vm.surveyTitle) + " ")
                ])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          !_vm.dataLoading
            ? _c("v-col", { attrs: { align: "center" } }, [
                _c("div", { staticClass: "text-h4" }, [
                  _vm._v(
                    " Enter your " +
                      _vm._s(_vm.accessCodeLength) +
                      "-digit access code: "
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "input-wrapper" },
                  [
                    _c("AccessCodeInput", {
                      attrs: {
                        placeholder: "",
                        "survey-id": _vm.surveyId,
                        "access-code-length": _vm.accessCodeLength
                      },
                      on: { "access-granted": _vm.onAccessGranted }
                    })
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }