import { render, staticRenderFns } from "./NoResponsesDashboardItem.vue?vue&type=template&id=78a7e320&"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
installComponents(component, {VCardText})


/* hot reload */
if (module.hot) {
  var api = require("/Users/davewillie/code/muse/muse-cloud/admin-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('78a7e320')) {
      api.createRecord('78a7e320', component.options)
    } else {
      api.reload('78a7e320', component.options)
    }
    module.hot.accept("./NoResponsesDashboardItem.vue?vue&type=template&id=78a7e320&", function () {
      api.rerender('78a7e320', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Dashboard/Items/NoResponsesDashboardItem.vue"
export default component.exports