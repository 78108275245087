// TODO: Move these into a Survey model
export const LanguageOptions = {
  arabic: {
    name: 'Arabic',
    localised: 'العربية',
    extended: 'Arabic (العربية)',
    code: 'AR'
  },
  english: {
    name: 'English',
    localised: 'English',
    extended: 'English (English)',
    code: 'EN'
  },
  french: {
    name: 'French',
    localised: 'français',
    extended: 'French (français)',
    code: 'FR'
  },
  german: {
    name: 'German',
    localised: 'Deutsch',
    extended: 'German (Deutsch)',
    code: 'DE'
  },
  italian: {
    name: 'Italian',
    localised: 'italiano',
    extended: 'Italian (italiano)',
    code: 'IT'
  }
};

export const getLanguage = code => {
  return Object.values(LanguageOptions).find(
    language => language.code === code
  );
};
