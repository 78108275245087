<template>
  <v-avatar
    class="white--text text-caption"
    :class="complete ? 'complete' : 'incomplete'"
    height="28"
    min-width="28"
    width="28"
  >
    {{ language.code }}
  </v-avatar>
</template>

<script>
export default {
  name: 'LanguageAvatar',
  props: {
    language: {
      type: Object,
      required: true
    },
    complete: {
      type: Boolean,
      required: false,
      default: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/main.scss';
.complete {
  background-color: map-get($grey, 'darken-2');
}

.incomplete {
  background-color: map-get($grey, 'lighten-1');
}
</style>
