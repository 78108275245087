var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex" },
    _vm._l(_vm.buttons, function(btn, i) {
      return _c(
        "v-tooltip",
        {
          key: i,
          attrs: { top: "" },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  return [
                    _c(
                      "div",
                      _vm._g({}, on),
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-4",
                            attrs: {
                              color: btn.colors,
                              width: "36px",
                              height: "36px",
                              disabled: btn.disabled,
                              "x-small": ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleClick(btn.event)
                              }
                            }
                          },
                          [
                            _c("v-icon", { class: btn.iconClasses }, [
                              _vm._v(_vm._s(btn.icon))
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ],
            null,
            true
          )
        },
        [_c("span", [_vm._v(_vm._s(btn.toolTipText))])]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }