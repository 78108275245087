<template>
  <div class="constrained-area-parent">
    <link
      v-for="(url, index) in urlsToUse"
      :key="index"
      rel="preload"
      :href="url"
      as="image"
    />

    <div :class="gridClass">
      <div v-for="(url, index) in urlsToUse" :key="index">
        <img :src="url" class="grid-img" />
      </div>
    </div>
  </div>
</template>

<script>
import { questionVisualisationHelper } from '../../mixins/SuperUtils';

export default {
  components: {},
  mixins: [questionVisualisationHelper],
  props: {
    questionStat: {
      required: true,
      type: Object
    },
    theme: {
      required: true,
      type: Object
    }
  },
  computed: {
    gridDimensions() {
      return (
        this.questionStat.publicDisplayOptions?.gridDimensions ??
        'FOUR_BY_THREE'
      );
    },
    urlCount() {
      return this.questionStat.urls.length;
    },
    urlsToUse() {
      const toUse = {
        FOUR_BY_THREE: 12,
        THREE_BY_TWO: 6,
        ONE_BY_ONE: 1,
        ONE_BY_ONE_FULLSCREEN: 1
      }[this.gridDimensions];
      return this.questionStat.urls.slice(0, toUse);
    },
    gridClass() {
      if (
        this.urlCount > 6 &&
        this.gridDimensions === 'FOUR_BY_THREE'
      ) {
        return 'four-by-three';
      }

      if (
        this.urlCount > 1 &&
        this.gridDimensions == 'THREE_BY_TWO'
      ) {
        return 'three-by-two';
      }

      if (this.gridDimensions === 'ONE_BY_ONE_FULLSCREEN') {
        return 'one-by-one-fullscreen';
      } else {
        return 'one-by-one';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
// Sizing of parent area
.constrained-area-parent {
  max-height: 100%;
  max-width: 100%;
  position: relative;
}

.four-by-three > div > .grid-img {
  width: 100%;
  max-width: 100%;
  height: 22vh;
  max-height: 22vh;
  object-fit: contain;
}

.three-by-two > div > .grid-img {
  width: 100%;
  max-width: 100%;
  height: 35vh;
  max-height: 35vh;
  object-fit: contain;
}

.one-by-one > div > .grid-img {
  width: 100%;
  max-width: 100%;
  max-height: 75vh;
  object-fit: contain;
}

.one-by-one-fullscreen > div > .grid-img {
  width: 100%;
  max-width: 100%;
  object-fit: contain;
}

// Define dimenions of columns
.one-by-one {
  display: grid;
  grid-template-columns: 1fr;
}

.one-by-one-fullscreen {
  display: grid;
  grid-template-columns: 1fr;
}

.three-by-two {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  min-width: 0;
  max-width: 100%;
  max-height: 100%;
  gap: 10px;
}

.four-by-three {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  min-width: 0;
  max-width: 100%;
  max-height: 100%;
  gap: 10px;
}
</style>
