import { render, staticRenderFns } from "./PageHeaderBar.vue?vue&type=template&id=33900c3a&functional=true&"
import script from "./PageHeaderBar.vue?vue&type=script&lang=js&"
export * from "./PageHeaderBar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer})


/* hot reload */
if (module.hot) {
  var api = require("/Users/davewillie/code/muse/muse-cloud/admin-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('33900c3a')) {
      api.createRecord('33900c3a', component.options)
    } else {
      api.rerender('33900c3a', component.options)
    }
    module.hot.accept("./PageHeaderBar.vue?vue&type=template&id=33900c3a&functional=true&", function () {
      api.rerender('33900c3a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Common/PageHeaderBar.vue"
export default component.exports