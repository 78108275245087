<template>
  <div>
    <p class="grey--text text--darken-1 mb-2 mt-4">Grid Dimensions</p>
    <v-btn-toggle v-model="gridDimensions">
      <v-btn
        v-for="gridOption in gridDimensionOptions"
        :key="gridOption.key"
        :value="gridOption.key"
      >
        {{ gridOption.btnText }}
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
import QuestionTypeConfig from '@/utils/QuestionTypeConfig';

export default {
  name: 'DrawingPublicDisplayOptions',
  components: {},
  props: {
    publicDisplayConfig: {
      type: Object,
      required: true
    }
  },
  computed: {
    questionType() {
      return this.publicDisplayConfig.question.config.questionType;
    },
    gridDimensionOptions() {
      return [
        { key: 'ONE_BY_ONE_FULLSCREEN', btnText: '1⨉1 (Fullscreen)' },
        { key: 'ONE_BY_ONE', btnText: '1⨉1' },
        { key: 'THREE_BY_TWO', btnText: '3⨉2' },
        { key: 'FOUR_BY_THREE', btnText: '4⨉3' }
      ];
    },
    gridDimensions: {
      get() {
        return (
          this.publicDisplayConfig.options?.gridDimensions ??
          this.gridDimensionOptions[0].key
        );
      },
      set(value) {
        this.publicDisplayConfig.options.gridDimensions = value;
        this.$emit('options-changed');
      }
    }
  },
  mounted() {
    if (!this.publicDisplayConfig.options) {
      this.publicDisplayConfig.options = QuestionTypeConfig[
        this.questionType
      ].defaultPublicDisplayOptions();
    }
  }
};
</script>
