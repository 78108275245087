var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", { staticClass: "grey--text text--darken-1 mb-2 mt-4" }, [
        _vm._v("Grid Dimensions")
      ]),
      _c(
        "v-btn-toggle",
        {
          model: {
            value: _vm.gridDimensions,
            callback: function($$v) {
              _vm.gridDimensions = $$v
            },
            expression: "gridDimensions"
          }
        },
        _vm._l(_vm.gridDimensionOptions, function(gridOption) {
          return _c(
            "v-btn",
            { key: gridOption.key, attrs: { value: gridOption.key } },
            [_vm._v(" " + _vm._s(gridOption.btnText) + " ")]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }