<template>
  <v-container
    class="short-link-redirect-container"
    fill-height
    fluid
  >
    <v-row align="center" justify="center">
      <v-progress-circular
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  computed: {
    shortId() {
      return this.$route.params.shortId;
    },
    accessCode() {
      return this.$route.params.accessCode;
    }
  },
  mounted() {
    this.fetchPublicSurveyInfo({ shortId: this.shortId }).then(
      publicInfo => {
        this.$router.push(
          `/surveys/${publicInfo.surveyId}/tv?accessCode=${this.accessCode}`
        );
      }
    );
  },
  methods: {
    ...mapActions({
      fetchPublicSurveyInfo: 'publicDisplay/fetchPublicSurveyInfo'
    })
  }
};
</script>

<style scoped>
html {
  overflow: hidden;
}
</style>
