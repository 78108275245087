<template>
  <div>
    <page-header-bar>
      <div v-if="!dataLoading" class="d-flex p-relative white--text">
        <div class="flex-grow-1 d-flex flex-column">
          <div class="fab-container p-absolute">
            <v-btn
              class="floating-button"
              color="grey lighten-2 black--text"
              dark
              small
              fab
              @click="closeSurveyTranslator()"
            >
              <v-icon>
                {{ translation ? 'close' : 'arrow_back' }}
              </v-icon>
            </v-btn>
          </div>
          <h5 class="text-h5 mb-2">
            {{ surveyTitle }}
          </h5>
          <div class="d-flex align-center mb-1">
            <v-icon class="white--text mr-2">
              format_list_numbered
            </v-icon>

            <div class="mr-2 font-weight-regular text-h6">
              {{ survey.userQuestionCount }}
              {{
                survey.userQuestionCount === 1
                  ? 'Question'
                  : 'Questions'
              }}
            </div>
            <survey-language-status
              :highlight="selectedLanguage"
              :language-status="languageTranslationStatus"
              @language-clicked="setDisplayLanguage"
            />
          </div>

          <div class="d-flex">
            <v-icon class="white--text mr-2">
              record_voice_over
            </v-icon>
            <h6 class="text-h6">
              {{ responseCount }}
            </h6>
          </div>
        </div>
        <div class="d-flex flex-column justify-center">
          <survey-page-header-buttons
            class="align-center"
            :survey-status="survey.status"
            :survey-has-questions="survey.questions.length > 1"
            :survey-has-responses="survey.responseCount > 0"
            @show-survey-preview="showPreviewDialog = true"
            @show-survey-publish="showSurveyPublishDialog"
            @edit-survey="editSurvey = true"
            @edit-survey-disclaimer="showDisclaimerDialog = true"
            @clear-survey-responses="showClearResponsesDialog = true"
          />
          <v-switch
            :v-model="survey.showDisclaimer"
            hide-details="true"
            color="white"
            :input-value="survey.showDisclaimer"
            @change="toggleShowDisclaimer($event)"
          >
            <template v-slot:label>
              <span class="white--text font-weight-regular">
                Show Survey Disclaimer
              </span>
            </template>
          </v-switch>
        </div>
      </div>
    </page-header-bar>
    <div v-if="dataLoading">
      <v-container class="pa-2">
        <question-list-loader />
      </v-container>
    </div>
    <v-container v-else class="pa-2">
      <v-tabs
        v-if="!translation"
        v-model="activeTab"
        class="pa-8"
        grow
      >
        <div
          v-for="(tab, i) in tabs"
          :key="tab.href"
          class="d-flex flex-grow-1"
        >
          <v-tab v-if="!tab.isDisabled" :href="tab.href">
            <v-icon class="mr-2">{{ tab.icon }}</v-icon
            >{{ tab.title }}
          </v-tab>

          <v-tooltip v-else :key="i" bottom>
            <template v-slot:activator="{ on }">
              <div
                class="d-flex flex-grow-1"
                v-on="hasResponses ? {} : on"
              >
                <v-tab :href="tab.href" :disabled="tab.isDisabled">
                  <v-icon class="mr-2">{{ tab.icon }}</v-icon>
                  {{ tab.title }}
                </v-tab>
              </div>
            </template>
            <span>
              {{ tab.tabToolTipText }}
            </span>
          </v-tooltip>
        </div>
        <v-tab-item value="questions">
          <questions-view
            :survey="survey"
            :questions="questions"
            :question-table-key="questionTableKey"
            :data-loading="dataLoading"
          />
        </v-tab-item>
        <v-tab-item value="dashboard">
          <dashboard-view
            :survey="survey"
            :stats="questionStats"
            :display-language="displayLanguage"
            @fetch-stats-with-date="fetchStats"
          />
        </v-tab-item>
        <v-tab-item
          v-if="survey.publicDisplayFeatureEnabled"
          value="public-display"
        >
          <public-display-config-view class="mt-4" :survey="survey" />
        </v-tab-item>
        <v-tab-item value="export">
          <survey-data-export-card
            class="mt-4"
            :start-date="survey.surveyStart"
            :end-date="survey.surveyEnd"
            :survey="survey"
          />
        </v-tab-item>
      </v-tabs>
      <survey-translator
        v-else
        :survey="survey"
        :translation-language="translation"
        @update-translations="updateTranslations"
        @close="closeTranslate"
      />
      <survey-preview-dialog
        :show.sync="showPreviewDialog"
        :selected-survey="survey"
        :questions-list="previewQuestions"
      />
      <survey-dialog
        :show.sync="editSurvey"
        :survey="survey"
        :organisations="organisations"
        @save="handleSurveySaved"
      />
      <survey-disclaimer-dialog
        v-model="showDisclaimerDialog"
        :survey="survey"
      />
      <survey-action-dialog
        :show.sync="showActionDialog"
        :survey="survey"
        :action="surveyAction"
        @dialog-action-confirmed="handleDialogActionConfirmed"
      />
      <clear-survey-responses-confirmation-dialog
        :show.sync="showClearResponsesDialog"
        :survey="survey"
        @clear-responses="clearSurveyResponses()"
        @cancel="showClearResponsesDialog = false"
      />
    </v-container>
  </div>
</template>

<script>
import PublicDisplayConfigView from '@/components/PublicDisplay/PublicDisplayConfigView';
import PageHeaderBar from '@/components/Common/PageHeaderBar';
import QuestionsView from '@/components/Survey/View/QuestionsView';
import DashboardView from '@/components/Dashboard/DashboardView';
import SurveyLanguageStatus from '@/components/Common/SurveyLanguageStatus';
import SurveyPreviewDialog from '@/components/Survey/View/SurveyPreviewDialog';
import SurveyDialog from '@/components/Survey/SurveyDialog';
import SurveyTranslator from '@/components/Survey/Edit/SurveyTranslator';
import SurveyDataExportCard from '@/components/Survey/View/SurveyDataExportCard';
import SurveyPageHeaderButtons from '@/components/Survey/View/SurveyPageHeaderButtons';
import SurveyDisclaimerDialog from '@/components/Survey/Edit/SurveyDisclaimerDialog';
import ClearSurveyResponsesConfirmationDialog from '@/components/Survey/Edit/ClearSurveyResponsesConfirmationDialog';
import SurveyActionDialog from '@/components/Survey/SurveyActionDialog';
import { surveyHelper } from '@/mixins/SurveyHelper';
import { mapActions, mapState } from 'vuex';
import { SurveyStatus } from '@/enums/index';
import QuestionListLoader from '@/components/Survey/View/Loader/QuestionListLoader';

export default {
  name: 'SurveyPage',
  components: {
    PageHeaderBar,
    SurveyLanguageStatus,
    SurveyPreviewDialog,
    QuestionsView,
    PublicDisplayConfigView,
    DashboardView,
    SurveyDialog,
    SurveyTranslator,
    SurveyDataExportCard,
    QuestionListLoader,
    SurveyPageHeaderButtons,
    SurveyDisclaimerDialog,
    SurveyActionDialog,
    ClearSurveyResponsesConfirmationDialog
  },
  mixins: [surveyHelper],
  data: () => ({
    activeTab: 'questions',
    questionTableKey: 0,
    numLoadingCards: 4,
    dataLoading: true,
    translation: undefined,
    editSurvey: false,
    showDisclaimerDialog: false,
    showPreviewDialog: false,
    showActionDialog: false,
    showClearResponsesDialog: false,
    surveyAction: '',
    displayLanguage: ''
  }),
  computed: {
    ...mapState('survey', ['survey']),
    ...mapState('stats', ['stats']),
    ...mapState('organisation', ['organisations']),
    tabs() {
      const QUESTIONS_TAB = {
        title: 'Questions',
        icon: 'format_list_numbered',
        href: '#questions',
        isDisabled: false
      };
      const DASHBOARD_TAB = {
        title: 'Dashboard',
        icon: 'bar_chart',
        href: '#dashboard',
        tabToolTipText:
          'Dashboard will be available once the survey has responses',
        isDisabled: !this.hasResponses
      };
      const PUBLIC_DISPLAY_TAB = {
        title: 'Public Display',
        icon: 'tv',
        href: '#public-display',
        isDisabled: false,
        isHidden: !this.survey.publicDisplayFeatureEnabled
      };
      const EXPORT_DATA_TAB = {
        title: 'Export Data',
        icon: 'save_alt',
        href: '#export',
        tabToolTipText:
          'Export data will be available once the survey has responses',
        isDisabled: !this.hasResponses
      };

      return [
        QUESTIONS_TAB,
        DASHBOARD_TAB,
        PUBLIC_DISPLAY_TAB,
        EXPORT_DATA_TAB
      ].filter(t => !t.isHidden);
    },
    questions() {
      return this.survey.questions;
    },
    surveyId() {
      return this.$route.params.id;
    },
    surveyTitle() {
      return this.survey ? this.survey.title : '';
    },
    questionStats() {
      return this.stats ?? [];
    },
    isPublished() {
      if (this.dataLoading) {
        return false;
      }

      return this.survey.status === SurveyStatus.PUBLISHED;
    },
    hasResponses() {
      // Check for existence of survey first as it may not have loaded
      return !this.dataLoading && this.survey.responseCount > 0;
    },
    disabledHref() {
      return `#${this.activeTab}`;
    },
    previewQuestions() {
      return this.questions.filter(
        question =>
          question.userConfigurable &&
          question.config.questionType !== 'NO_TYPE'
      );
    },
    selectedLanguage() {
      // If we're on the questions tab, then default to the primary language
      // unless we're translating, else go with the selected display languae
      if (this.activeTab == 'questions') {
        return this.translation ?? this.survey.primaryLanguage;
      } else {
        return this.displayLanguage;
      }
    },
    hasPreviewQuestions() {
      return this.previewQuestions.length > 0;
    },
    responseCount() {
      const responses = this.survey.responseCount;
      return responses === 1
        ? '1 response'
        : `${responses} responses`;
    }
  },
  mounted() {
    this.fetchSurvey(this.surveyId).then(() => {
      this.dataLoading = false;
      this.displayLanguage = this.survey.primaryLanguage;
    });
    // TODO: Move to DashboardView
    const stats = {
      surveyId: this.surveyId
    };
    this.$store.dispatch('stats/fetchStats', stats);

    if (this.$route.query.tab) {
      if (this.tabs.includes(this.$route.query.tab)) {
        this.activeTab = this.$route.query.tab;
      }
    } else {
      this.activeTab = 'questions';
    }
  },
  methods: {
    ...mapActions({
      fetchSurvey: 'survey/fetchSurvey',
      updateSurvey: 'survey/updateSurvey',
      saveQuestion: 'question/saveQuestion',
      clearResponses: 'survey/clearResponses'
    }),
    updateSurveyStatus() {
      this.survey.status =
        this.survey.status == SurveyStatus.PUBLISHED
          ? SurveyStatus.DRAFT
          : SurveyStatus.PUBLISHED;
    },
    updateTranslations(question, onFinish) {
      if (Array.isArray(question)) {
        const promises = question.map(q => this.saveQuestion(q));
        Promise.all(promises).then(() => {
          this.fetchSurvey(this.surveyId).then(() => onFinish());
        });
      } else {
        this.saveQuestion(question).then(() => onFinish());
      }
    },
    closeSurveyTranslator() {
      if (this.translation) {
        this.translation = undefined;
        this.displayLanguage = this.survey.primaryLanguage;
        return;
      }
      this.$router.go(-1);
    },
    setDisplayLanguage(language) {
      this.displayLanguage = language;

      if (this.activeTab === 'questions') {
        this.translate(language);
      }
    },
    translate(language) {
      if (this.translation !== undefined) {
        if (
          language === this.survey.primaryLanguage ||
          language === this.translation
        ) {
          return this.closeTranslate();
        }
      }
      if (language === this.survey.primaryLanguage) {
        return alert('Cannot translate the primary language');
      }
      this.translation = language;
    },
    closeTranslate() {
      this.translation = undefined;
    },
    fetchStats(dates) {
      const stats = {
        surveyId: this.surveyId,
        startDate: dates.startDate,
        endDate: dates.endDate
      };
      this.$store.dispatch('stats/fetchStats', stats);
    },
    showSurveyPublishDialog(action) {
      this.surveyAction = action;
      this.showActionDialog = true;
    },
    handleSurveySaved(survey) {
      survey.base64EncodedImageData = null;
      survey.imageDeleted = false;
      this.updateSurvey(survey);
    },
    handleDialogActionConfirmed() {
      const survey = { ...this.survey };
      survey.base64EncodedImageData = null;
      survey.imageDeleted = false;

      survey.status =
        survey.status == SurveyStatus.PUBLISHED
          ? SurveyStatus.DRAFT
          : SurveyStatus.PUBLISHED;

      this.updateSurveyStatus();
      this.updateSurvey(survey);
    },
    closeClearResponsesDialog() {
      this.showClearResponsesDialog = false;
    },
    clearSurveyResponses() {
      this.showClearResponsesDialog = false;
      this.clearResponses(this.survey).then(() => {
        this.fetchSurvey(this.surveyId);
      });
    },
    toggleShowDisclaimer(newValue) {
      this.survey.showDisclaimer = newValue;
      this.updateSurvey(this.survey);
    }
  }
};
</script>
<style lang="scss" scoped>
.controls {
  padding: 2em 2em 0 2em;
}
.fab-container {
  left: -5em;
  top: 2em;
}
</style>
