var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "survey-card d-flex flex-column",
      attrs: { hover: "" },
      on: { click: _vm.routeToSurveyPage }
    },
    [
      _c(
        "survey-card-header-container",
        {
          staticClass: "p-relative",
          attrs: { "image-id": _vm.survey.introBackgroundImageId }
        },
        [
          _c("v-card-title", { staticClass: "pa-0 h-100 d-flex flex-nowrap" }, [
            _c(
              "div",
              { staticClass: "align-self-end flex-grow-1 white--text" },
              [
                _c(
                  "transition",
                  { attrs: { name: "survey-field", mode: "out-in" } },
                  [
                    _c(
                      "div",
                      {
                        key: _vm.survey.title,
                        staticClass:
                          "text-h5 survey-title font-weight-bold clickable"
                      },
                      [_vm._v(" " + _vm._s(_vm.survey.title) + " ")]
                    )
                  ]
                ),
                _vm.showOrganisation
                  ? _c("div", { staticClass: "text-subtitle-1" }, [
                      _vm._v(" " + _vm._s(_vm.organisationName) + " ")
                    ])
                  : _vm._e()
              ],
              1
            )
          ]),
          _c("survey-actions-menu", {
            staticClass: "survey-actions-menu",
            attrs: { "survey-status": _vm.survey.status },
            on: { "action-click": _vm.handleSurveyAction }
          })
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "my-0 pa-4 d-flex flex-column flex-grow-1" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "question-count text-h6 font-weight-medium grey--text text--darken-2 pt-0",
                  attrs: { cols: "6" }
                },
                [
                  _c(
                    "transition",
                    { attrs: { name: "survey-field", mode: "out-in" } },
                    [
                      _c("span", { key: _vm.survey.userQuestionCount }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.survey.userQuestionCount) +
                            " " +
                            _vm._s(
                              _vm.survey.userQuestionCount === 1
                                ? "question"
                                : "questions"
                            ) +
                            " "
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pt-0 h-100", attrs: { cols: "6" } },
                [
                  _c("survey-language-status", {
                    staticClass: "language-chips",
                    attrs: { "language-status": _vm.languageTranslationStatus }
                  })
                ],
                1
              )
            ],
            1
          ),
          !_vm.isPublished
            ? _c("survey-issues", {
                attrs: { survey: _vm.survey, "survey-issues": _vm.issues }
              })
            : _vm._e(),
          _c(
            "transition",
            { attrs: { name: "survey-field", mode: "out-in" } },
            [
              _c(
                "span",
                { key: _vm.isPublished },
                [
                  _vm.isPublished
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pt-3 pb-0", attrs: { cols: "6" } },
                            [
                              _c(
                                "transition",
                                {
                                  key: _vm.survey.responseCount,
                                  attrs: {
                                    name: "survey-field",
                                    mode: "out-in"
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    { key: _vm.survey.responseCount },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "count grey--text text--darken-4 text-h4 mb-0 font-weight-medium"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.survey.responseCount) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "grey--text text-subtitle-1 mb-0"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.survey.responseCount === 1
                                                  ? "voice heard"
                                                  : "voices heard"
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex pb-0 pr-4 justify-end",
                              attrs: { cols: "6" }
                            },
                            [
                              _c("status-chip", {
                                staticClass: "align-self-end",
                                attrs: { status: _vm.survey.status }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c(
                        "v-row",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-3 pb-0 d-flex justify-end",
                              attrs: { cols: "6" }
                            },
                            [
                              _c("status-chip", {
                                attrs: { status: _vm.survey.status }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }