<template>
  <v-dialog v-model="value" scrollable max-width="1000px" persistent>
    <v-card height="800px">
      <v-card-title class="grey lighten-2 py-5">
        <h3 class="font-weight-medium">
          Disclaimer
        </h3>
      </v-card-title>
      <div class="px-6">
        <div class="my-4">
          <language-avatar
            v-for="(language, i) in surveyLanguages"
            :key="i"
            class="mr-1 clickable"
            :language="{ code: language }"
            :complete="language == targetLanguage"
            @click.native="setTargetLanguage(language)"
          />
        </div>
        <editor-menu-bar
          v-slot="{ commands, isActive }"
          class="ml-n3 mb-3"
          :editor="editor"
        >
          <div>
            <v-btn
              v-for="(button, i) in editorMenuBarButtons"
              :key="i"
              width="30px"
              height="30px"
              class="subtitle-1"
              x-small
              depressed
              :color="buttonColor(button, isActive)"
              @click="commands[button.extension]()"
            >
              <v-icon>
                {{ button.icon }}
              </v-icon>
            </v-btn>
          </div>
        </editor-menu-bar>
      </div>
      <v-card-text>
        <editor-content class="editor fill-height" :editor="editor" />
      </v-card-text>
      <v-card-actions class="px-6 pb-6 align-start">
        <span v-if="save.error" class="d-flex align-center">
          <v-icon color="red" class="mr-4">error</v-icon>
          An error occurred saving your changes. If errors persist,
          check your internet connection or try again later.
        </span>
        <v-spacer />
        <v-btn small @click="$emit('input', false)">close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import {
  OrderedList,
  BulletList,
  ListItem,
  Bold,
  Italic,
  Underline,
  History
} from 'tiptap-extensions';
import LanguageAvatar from '@/components/Common/LanguageAvatar';
import { mapActions } from 'vuex';

export default {
  name: 'SurveyDisclaimerDialog',
  components: {
    EditorContent,
    EditorMenuBar,
    LanguageAvatar
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    survey: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      editor: null,
      targetLanguage: null,
      saveTimerId: null,
      save: {
        success: false,
        error: false
      }
    };
  },
  computed: {
    editorMenuBarButtons() {
      return [
        {
          extension: 'bold',
          icon: 'format_bold'
        },
        {
          extension: 'italic',
          icon: 'format_italic'
        },
        {
          extension: 'underline',
          icon: 'format_underline'
        },
        {
          extension: 'bullet_list',
          icon: 'format_list_bulleted'
        },
        {
          extension: 'ordered_list',
          icon: 'format_list_numbered'
        },
        {
          extension: 'undo',
          icon: 'undo'
        },
        {
          extension: 'redo',
          icon: 'redo'
        }
      ];
    },
    surveyLanguages() {
      return [
        this.survey.primaryLanguage,
        ...this.survey.secondaryLanguages
      ];
    }
  },
  mounted() {
    this.targetLanguage = this.survey.primaryLanguage;
    this.editor = new Editor({
      extensions: [
        new BulletList(),
        new ListItem(),
        new OrderedList(),
        new Bold(),
        new Italic(),
        new Underline(),
        new History()
      ],
      content: this.survey.disclaimer.languageMap[
        this.targetLanguage
      ],
      onUpdate: ({ getHTML }) => {
        this.editor.options.content = getHTML();
        this.queueSave();
      }
    });
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    ...mapActions({ updateSurvey: 'survey/updateSurvey' }),
    buttonColor(button, isActive) {
      // Extensions like bold, underline can be active in the editor,
      // where as other extensions like undo and redo can be invoked
      // but do not remain active after invocation
      let extensionActive = isActive[button.extension];
      return extensionActive && extensionActive()
        ? 'grey lighten-3'
        : 'white';
    },
    queueSave() {
      this.survey.disclaimer = {
        languageMap: {
          ...this.survey.disclaimer.languageMap,
          [this.targetLanguage]: this.editor.options.content
        }
      };

      if (this.saveTimerId) {
        clearTimeout(this.saveTimerId);
      }
      this.saveTimerId = setTimeout(() => {
        this.handleSave();
      }, 1000);
    },
    handleSave() {
      this.updateSurvey(this.survey).catch(() => {
        this.save.error = true;
      });
    },
    setTargetLanguage(language) {
      this.targetLanguage = language;
      this.editor.setContent(
        this.survey.disclaimer.languageMap[language]
      );
      this.save = {
        success: false,
        error: false
      };
    }
  }
};
</script>

<style scoped lang="scss">
@import '../../../scss/main.scss';
::v-deep .editor {
  font-size: 16px;
  color: map-get($grey, 'darken-3');
  li:not(:last-child) p {
    margin-bottom: 0;
  }
  overflow-y: auto;
  border: map-get($grey, 'lighten-2') solid 1px;
  border-radius: 4px;

  .ProseMirror {
    height: 100%;
    padding: 4px;
    &:focus {
      outline: none;
    }
  }
}
</style>
