var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { scrollable: "", "max-width": "1000px", persistent: "" },
      model: {
        value: _vm.value,
        callback: function($$v) {
          _vm.value = $$v
        },
        expression: "value"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { height: "800px" } },
        [
          _c("v-card-title", { staticClass: "grey lighten-2 py-5" }, [
            _c("h3", { staticClass: "font-weight-medium" }, [
              _vm._v(" Disclaimer ")
            ])
          ]),
          _c(
            "div",
            { staticClass: "px-6" },
            [
              _c(
                "div",
                { staticClass: "my-4" },
                _vm._l(_vm.surveyLanguages, function(language, i) {
                  return _c("language-avatar", {
                    key: i,
                    staticClass: "mr-1 clickable",
                    attrs: {
                      language: { code: language },
                      complete: language == _vm.targetLanguage
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.setTargetLanguage(language)
                      }
                    }
                  })
                }),
                1
              ),
              _c("editor-menu-bar", {
                staticClass: "ml-n3 mb-3",
                attrs: { editor: _vm.editor },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var commands = ref.commands
                      var isActive = ref.isActive
                      return [
                        _c(
                          "div",
                          _vm._l(_vm.editorMenuBarButtons, function(button, i) {
                            return _c(
                              "v-btn",
                              {
                                key: i,
                                staticClass: "subtitle-1",
                                attrs: {
                                  width: "30px",
                                  height: "30px",
                                  "x-small": "",
                                  depressed: "",
                                  color: _vm.buttonColor(button, isActive)
                                },
                                on: {
                                  click: function($event) {
                                    commands[button.extension]()
                                  }
                                }
                              },
                              [
                                _c("v-icon", [
                                  _vm._v(" " + _vm._s(button.icon) + " ")
                                ])
                              ],
                              1
                            )
                          }),
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("editor-content", {
                staticClass: "editor fill-height",
                attrs: { editor: _vm.editor }
              })
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "px-6 pb-6 align-start" },
            [
              _vm.save.error
                ? _c(
                    "span",
                    { staticClass: "d-flex align-center" },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-4", attrs: { color: "red" } },
                        [_vm._v("error")]
                      ),
                      _vm._v(
                        " An error occurred saving your changes. If errors persist, check your internet connection or try again later. "
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { small: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("input", false)
                    }
                  }
                },
                [_vm._v("close")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }